





































































import { mapGetters } from "vuex";
import tippy, {delegate as tippyDelegate} from "tippy.js";
import IconAdd from "@/icons/plus-square-solid.svg";
import IconSpa from "@/icons/caret-square-right-solid.svg";
import IconBlank from "@/icons/blank.svg";
import {
  formatCell,
  getSWISLed,
  getMasaoLed,
  formatKCell,
} from "./tree-helpers";
import TreeNodeAdd from "./node/TreeNodeAdd.vue";
import TreeCellLed from "./node/TreeCellLed.vue";
import IconMoveLeft from "@/icons/arrow-alt-circle-left-solid.svg";

export default {
  name: "TreeTrunk",
  components: {
    IconMoveLeft,
    TreeNodeAdd,
    IconAdd,
    IconSpa,
    IconBlank,
    TreeCellLed,
  },
  props: {
    trunk: {},
    editable: {},
    advanced: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      childToAdd: null,
    };
  },
  computed: {
    ...mapGetters({
      cia: "App/colSet/cia",
      noColSet: "App/colSet/noColSet",
    }),
    node() {
      if (!this.trunk) return null;
      return {
        name: this.trunk.name,
        devops: !!parseInt(this.trunk.devops),
        vcr_days: this.trunk.vcr_days ? parseFloat(this.trunk.vcr_days) : "",
        agg_init_sold_hours: formatCell(this.trunk.agg_init_sold_hours),
        agg_sold_hours: formatCell(this.trunk.agg_sold_hours),
        agg_estimated_hours: formatCell(this.trunk.agg_estimated_hours),
        agg_pecc_hours: formatCell(this.trunk.agg_pecc_hours),
        agg_pecm_hours: formatCell(this.trunk.agg_pecm_hours),
        agg_passed_hours: formatCell(this.trunk.agg_passed_hours),
        agg_planned_hours: formatCell(this.trunk.agg_planned_hours),
        agg_done_ratio: Math.round(this.trunk.agg_done_ratio),
      };
    },
    label() {
      return this.node.name;
    },
    swis() {
      return getSWISLed(this.trunk.agg_estimated_hours, this.aggRaa);
    },
    swisLed() {
      return !this.swis;
    },
    swisLedMessage() {
      return this.swis;
    },
    raf() {
      return formatKCell(this.trunk.agg_raf);
    },
    dpp() {
      return formatKCell(this.trunk.agg_dpp);
    },
    dad() {
      return formatKCell(this.trunk.agg_dad);
    },
    raa() {
      return formatCell(this.trunk.agg_raa);
    },
    masao() {
      return getMasaoLed(this.trunk, this.aggRaa);
    },
    masaoLed() {
      return !this.masao;
    },
    masaoLedMessage() {
      return this.masao;
    },
  },
  methods: {
    openAddingChild(e) {
      if (!this.editable) return;
      this.childToAdd = {
        parentIssue: { project_id: this.trunk.id },
        parentElement: e.currentTarget.previousSibling,
      };
    },
    closeAddingChild() {
      this.childToAdd = null;
    },
    peccToSold() {
      if (!this.node.agg_pecc_hours) return
      if (!window.confirm(`Êtes-vous sûr de vouloir déplacer ces ${this.node.agg_pecc_hours} jours de PECC dans le Vendu ?`)) return;
      this.$emit('peccToSold')
    },
  },
  mounted() {
    if (!this.$refs.addChild) return;
    tippy(this.$refs.addChild, {
      content: 'Ajouter une tâche',
    });
    tippyDelegate(this.$refs.trunk, {
      target: '.pecc_to_sold',
      content: 'Passer en vendu',
    });
  }
};
